import {
  Home,
  Choose,
  ATF,
  ATFResult,
  DPF,
  DPFResult,
  GasolineSystem,
  GasolineSystemResult,
  ACSystem,
  ACSystemResult,
} from "../../routes";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

export default function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/choose" children={<Choose />} />
          <Route
            path="/services/atf-change/:price/:count/:volume/result"
            children={<ATFResult />}
          />
          <Route
            path="/services/dpf-clean/:price/:count/result"
            children={<DPFResult />}
          />
          <Route
            path="/services/gasoline-system-clean/:price/:countGasoline/:countDiesel/result"
            children={<GasolineSystemResult />}
          />
          <Route
            path="/services/ac-system-clean/:price/:count/result"
            children={<ACSystemResult />}
          />
          <Route
            path="/services/atf-change/:price?/:count?/:volume?"
            children={<ATF />}
          />
          <Route path="/services/dpf-clean/:price?/:count?" children={<DPF />} />
          <Route
            path="/services/gasoline-system-clean/:price?/:countGasoline?/:countDiesel?"
            children={<GasolineSystem />}
          />
          ;
          <Route
            path="/services/ac-system-clean/:price?/:count?"
            children={<ACSystem />}
          />
          ;
          <Route path="/" children={<Home />} />
        </Switch>
      </div>
    </Router>
  );
}
