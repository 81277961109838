import styled, { css } from "styled-components";
import { LogoIcon } from './images';
import { Link } from "react-router-dom";

const Wrapper = styled(Link)`
  display: grid;
  grid-template-columns: 128px 130px;
  grid-gap: 13px;
  width: 256px;
  text-decoration: none;
`;

const Text = styled.p`
  text-transform: uppercase;
  margin: 0;
  font-family: HeliosCondC;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  text-align: left;
  color: #2A4763;

  ${props => props.inverted && css`
    color: #FFFFFF;
  `}
`;

export default function Logo(args) {
  return (
    <Wrapper to='/'>
      <img src={LogoIcon} alt="Liqui Moly"/>
      <Text inverted={args.inverted}>
        моторні оливи <br/>
        автохімія <br/>
        автокосметика
      </Text>
    </Wrapper>
  );
}