import { useState } from 'react';
import { useParams } from "react-router-dom";
import { 
  Backdrop, Wrapper, Title, 
  Main, TitleImage, Article,
  ColHeading, RowHeading, Cell,
  Input, Product
} from '../../service-styles.js';
import { Header, LinkButton } from '../../../../components';

import { 
  Dpf,
  ProLine2818,
  ProLine5147
} from './images';

export default function DPF() {
  let params = useParams();
  const defaultPrice = parseFloat(params.price) || 0;
  const defaultCount = parseFloat(params.count) || 0;
  const [price, setPrice] = useState(defaultPrice);
  const [count, setCount] = useState(defaultCount);

  return (
    <Backdrop>
      <Wrapper>
        <Header inverted/>
        <Main>
          <Title>Прилад для очистки DPF <TitleImage src={Dpf} alt='Druckbecherpistole and Lanze mit 5 Sprühsonden'/></Title>
          <Article>
            <ColHeading></ColHeading>
            <ColHeading>Ціна <br/> закупки</ColHeading>
            <ColHeading>Ціна <br/> продажу</ColHeading>
            <ColHeading>Націнка</ColHeading>

            <RowHeading>
              <Product src={ProLine2818} alt='2818 Pro-Line Dieselpartikelfilterreiniger 1l'/>
              Pro-Line <br/> DPF Reiniger
            </RowHeading>
            <Cell>1072,20</Cell>
            <Cell>1513,68</Cell>
            <Cell>41,2%</Cell>

            <RowHeading>
              <Product src={ProLine5147} alt='5147 Pro-Line JetClean Benzin-System-Reiniger 1l'/>
              Pro-Line <br/> DPF Spulung
            </RowHeading>
            <Cell>601,12</Cell>
            <Cell>848,64</Cell>
            <Cell></Cell>

            <RowHeading>Вартість <br/> послуги</RowHeading>
            <Cell withInput>
              <Input type='number' min='0' placeholder={defaultPrice} onChange={event => {
                setPrice(event.target.value);
              }}/>
            </Cell>
            <Cell textLeft>грн</Cell>
            <Cell></Cell>

            <RowHeading>Кількість <br/> послуг</RowHeading>
            <Cell withInput>
              <Input type='number' min='0' placeholder={defaultCount} onChange={event => {
                setCount(event.target.value);
              }}/>
            </Cell>
            <Cell textLeft>/місяць</Cell>
            <Cell></Cell>

          </Article>
          <LinkButton to={`/services/dpf-clean/${price || defaultPrice}/${count || defaultCount}/result`}>Розрахувати</LinkButton>
        </Main>
      </Wrapper>
    </Backdrop>
  );
}