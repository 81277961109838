import styled, { css } from "styled-components";

export const PrimaryButton = styled.span`
  display: inline-block;
  min-width: 278px;
  height: 48px;
  line-height: 48px;
  background: #ED1C24;
  border-radius: 3px;
  padding: 0 16px;

  text-transform: uppercase;
  color: #ffffff;
  font-size: 18px;
  font-family: Proxima Nova Lt;
  font-weight: bold;
  text-align: center;

  ${props => props.radius && css`
    border-radius: ${props.radius}px;
  `}
`;

export default function Button(args) {
  return (
    <PrimaryButton radius={args.radius}>{args.children}</PrimaryButton>
  );
}