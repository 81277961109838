import { 
  Backdrop, Wrapper, Title, 
  Main, ServiceWrapper, ServiceLink 
} from './choose-styles.js';
import { Header } from '../../components';
import { Service } from './components';
import {
  ACIcon, ACIconWhite,
  GasolineSystemIcon, GasolineSystemIconWhite,
  DPFIcon, DPFIconWhite,
  ATFluidIcon, ATFluidIconWhite 
} from './images';

export default function Choose() {
  return (
    <Backdrop>
      <Wrapper>
        <Header/>
        <Main>
          <Title>Обери послугу</Title>
          <ServiceWrapper>
            <ServiceLink to="/services/atf-change">
              <Service alt='Automatic Transmission Fluid' icon={ATFluidIcon} iconActive={ATFluidIconWhite}>
                Заміна олив в <br/> АКПП
              </Service>
            </ServiceLink>
            <ServiceLink to="/services/dpf-clean">
              <Service alt='DPF' icon={DPFIcon} iconActive={DPFIconWhite}>
                Чистка <br/> DPF
              </Service>
            </ServiceLink>
            <ServiceLink to="/services/gasoline-system-clean">
              <Service alt='Gasoline System' icon={GasolineSystemIcon} iconActive={GasolineSystemIconWhite}>
                Чистка паливної <br/> системи
              </Service>
            </ServiceLink>
            <ServiceLink to="/services/ac-system-clean">
              <Service alt='Air Conditioning' icon={ACIcon} iconActive={ACIconWhite}>
                Чистка <br/> кондиціонеру
              </Service>
            </ServiceLink>
          </ServiceWrapper>
        </Main>
      </Wrapper>
    </Backdrop>
  );
}