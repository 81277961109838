import styled from "styled-components";
import { Link } from "react-router-dom";

export const Backdrop = styled.div`
  background: rgba(0, 91, 170, 0.04);
  width: 100%;
  min-height: 100vh;
  position: absolute;
`;

export const Wrapper = styled.section`
  position: absolute;
  top: 41px;
  bottom: 41px;
  left: 41px;
  right: 41px;

  background: #ffffff;
  border-radius: 5px;

  text-align: center;

  display: grid;
  justify-content: center;
  align-content: center;
`;

export const TitleFirst = styled.p`
  margin: 0;
  font-size: 48px;
  line-height: 52px;

  text-transform: uppercase;

  color: #005BAA;

  font-family: "Gilroy";
`;

export const TitleSecond = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 48px;
  line-height: 52px;

  text-transform: uppercase;

  color: #005BAA;

  font-family: "Gilroy";
`;

export const Button = styled(Link)`
  display: inline-block;
  width: 308px;
  height: 70px;
  line-height: 70px;
  background: #ed1c24;
  border-radius: 5px;
  margin-top: 52px;
  margin-left: auto;
  margin-right: auto;

  text-transform: uppercase;
  color: #ffffff;
  text-decoration: none;
  font-family: Proxima Nova Lt;
  font-weight: bold;
  font-size: 24px;
`;
