import { useRef } from 'react'
import { useState } from 'react';
import styled from "styled-components"
import useHover from '@react-hook/hover'
import useEvent from '@react-hook/event'

const Wrapper = styled.div`
  position: relative;
  background: #FFFFFF;
  border-radius: 8px;
  height: 100%;
  overflow: hidden;

  :hover {
    background: #005BAA;

    > p {
      color: #FFFFFF;
    }
  }
`;

const Text = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 34px;
  color: #2A4763;
  margin: 31px 17px;
`;

const Icon = styled.img`
  position: absolute;
  bottom: 8px;
  right: 51px;
  transform: translateX(19%) translateY(-8%);
`;

export default function Service(args) {
  const target = useRef(null)
  const isHovering = useHover(target)
  const [isTouched, setTouched] = useState(false)

  useEvent(target, 'touchstart', (event) =>
    setTouched(true)
  )

  useEvent(target, 'touchend', (event) => {
    setTouched(false)
    // https://stackoverflow.com/a/17234319/3320044
    var el = target.current;
    var par = el.parentNode;
    var next = el.nextSibling;
    par.removeChild(el);
    setTimeout(function() {par.insertBefore(el, next);}, 0)
  })

  return (
    <Wrapper ref={target}>
      <Text>{args.children}</Text>
      <Icon src={isHovering || isTouched ? args.iconActive : args.icon} alt={args.alt}/>
    </Wrapper>
  );
}
