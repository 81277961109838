import styled from "styled-components";

export const Backdrop = styled.div`
  background: #005BAA;
  min-height: 100vh;
`;

export const Wrapper = styled.section`
  padding: 41px;
`;

export const Title = styled.h1`
  font-family: Proxima Nova Lt;
  font-weight: bold;
  font-size: 24px;
  line-height: 46px;
  color: #FFFFFF;
  margin: 49px 0px 18px;
`;

export const Main = styled.main`
  margin-left: 4px;TitleImage
`;

export const Article = styled.article`
  background: #FFFFFF;
  border-radius: 5px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;

  padding-top: 48px;
  padding-bottom: 48px;

  color: #2A4763;

  margin-bottom: 44px;

  position:relative;

  :after {
    position: absolute;
    border-left: 1px solid rgba(0, 91, 170, 0.04);
    content: '';
    height: 100%;
    top: 0;
    left: 50%;
  }
`;

export const Input = styled.input`
  background: rgba(0, 91, 170, 0.04);
  border-radius: 3px;
  border: none;
  width: 106.76px;
  height: 35.86px;
  padding: 0 15px;
  margin: 8px 0;
`;

export const Result = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  justify-content: center;
  align-content: start;

  margin-top: 9px;
`;

export const Label = styled.p`
  font-size: 14px;
  font-weight: 500;
  padding-left: 35px;
  margin-top: 19px;
  margin-bottom: 0;
  padding-bottom: 1em;
  position:relative;

  :after {
    position: absolute;
    border-bottom: 1px solid rgba(0, 91, 170, 0.04);
    content: '';
    width: 100%;
    bottom: 0;
    left: 0;
  }
`;

export const Value = styled.p`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0;
  padding-bottom: 1em;
  position:relative;

  :after {
    position: absolute;
    border-bottom: 1px solid rgba(0, 91, 170, 0.04);
    content: '';
    width: 100%;
    bottom: 0;
    left: 0;
  }
`;

export const Product = styled.img`
  margin: 0 auto;
`;