import { useState } from 'react';
import { useForm } from "react-hook-form";
import { LinkButton } from '../../components';
import { Title, Label, ButtonWrapper,
  TextCenter, Button } from './email-form-styles';

export default function EmailForm(args) {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [processing, setProcessing] = useState(false);
  const [processed, setProcessed] = useState(false);
  const onSubmit = data => {
    let body = args.body
    body.customer_email = data.email
    body.customer_name = data.name
    setProcessing(true)
    fetch('/php/mail.php', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' },
    })
      .then(res => res.json())
      .then(json => setProcessed(true))
      .catch(function(error) {
        console.warn(error)
      })
      .then(function() {
        setProcessing(false)
      })
  }

  if (!processed) {
    return(
      <div>
        <Title>Надіслати <br/> розрахунок</Title>

        <form onSubmit={handleSubmit(onSubmit)}>
          
          <Label errors={errors.name}>
            Ім’я <br/>
            <input type="name" placeholder="Олексій" {...register("name", { required: true })}/>
          </Label>
          <Label errors={errors.email}>
            E-mail <br/>
            <input type="email" placeholder="yourmail@admin.com" {...register("email", { required: true })}/>
          </Label>

          <ButtonWrapper>
            <TextCenter>
              <Button type="submit" disabled={processing}>Надіслати</Button>
            </TextCenter>
          </ButtonWrapper>
        </form>
      </div>
    )
  } else {
    return(
      <div>
        <Title>Дякуємо! <br/>Розрахунок надіслано</Title>

        <LinkButton to='/choose' radius={18}>до головного меню</LinkButton>
      </div>
    )
  }
}