import { Backdrop, Wrapper, TitleFirst, TitleSecond, Button } from './home-styles.js';

export default function Home() {
  return (
    <Backdrop>
      <Wrapper>
        <TitleFirst>on-line</TitleFirst>
        <TitleSecond>калькулятор прибутку</TitleSecond>
        <Button to="/choose">пуск</Button>
      </Wrapper>
    </Backdrop>
  );
}