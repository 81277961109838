import styled from "styled-components";
import { BackIcon } from './images';
import { Link } from "react-router-dom";

const TextCenter = styled.div`
  text-align: center;
  margin-top: 44px;
`

const Icon = styled.img`
  :hover {
    cursor: pointer;
  }
`

export default function Back(args) {
  return (
    <TextCenter>
      <Link to={args.to}>
        <Icon src={BackIcon} alt="Back"/>
      </Link>
    </TextCenter>
  );
}