import styled, { css } from "styled-components";

export const Backdrop = styled.div`
  background: #005BAA;
  min-height: 100vh;
`;

export const Wrapper = styled.section`
  padding: 41px;
`;

export const Title = styled.h1`
  font-family: Proxima Nova Lt;
  font-weight: bold;
  font-size: 24px;
  line-height: 46px;
  color: #FFFFFF;
  margin: 9px 5px 18px;
`;

export const Main = styled.main`
  margin-left: 4px;
`;

export const TitleImage = styled.img`
  vertical-align: bottom;
  margin-left: 21px;
`;

export const Article = styled.article`
  background: #FFFFFF;
  border-radius: 5px;

  display: grid;
  grid-template-columns: 23% 26% 24% 27%;

  padding-top: 25px;
  padding-bottom: 71px;

  color: #2A4763;

  margin-bottom: 44px;
  position: relative;
`;

export const ColHeading = styled.span`
  display: grid;
  align-content: center;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 18px;
`;

export const RowHeading = styled.p`
  position: relative;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  padding: 6px 0px 12px 34px;
  line-height: 19px;

  :after {
    position: absolute;
    border-bottom: 1px solid rgba(0, 91, 170, 0.04);
    content: '';
    width: 100%;
    bottom: 0;
    left: 0;
  }
`;

export const Product = styled.img`
  position: absolute;
  left: 11px;
  top: 4px;
`;

export const Cell = styled.p`
  position: relative;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  margin: 0;
  padding: 1em 0;

  ${props => props.textLeft && css`
    text-align: left;
    padding-left: 9px;
  `}

  ${props => props.withInput && css`
    padding: 0;
  `}

  ${props => props.withAsterisk && css`
    :before {
      position: absolute;
      content: '*';
      top: -7px;
      right: 9px;
      font-weight: bold;
      font-size: 18px;
      line-height: 34px;
      color: #2A4763;
    }    
  `}

  :after {
    position: absolute;
    border-bottom: 1px solid rgba(0, 91, 170, 0.04);
    content: '';
    width: 100%;
    bottom: 0;
    left: 0;
  }
`;

export const Input = styled.input`
  background: rgba(0, 91, 170, 0.04);
  border-radius: 3px;
  border: none;
  width: 106.76px;
  height: 35.86px;
  padding: 0 15px;
  margin: 8px 0;
  text-align: center;
`;

export const Footnote = styled.p`
  position: absolute;
  bottom: 24px;
  left: 37px;
  font-size: 12px;
  line-height: 23px;
  color: #2A4763;
`;