import { useParams } from "react-router-dom";
import {
  Backdrop,
  Wrapper,
  Title,
  Main,
  Article,
  Result,
  Label,
  Value,
  Product,
} from "../../service-result-styles.js";
import { Header, EmailButton, Back } from "../../../../components";

import { Werkstattwagen } from "./images";

export default function GasolineSystemResult() {
  let { price, countGasoline, countDiesel } = useParams();
  price = parseFloat(price)
  countGasoline = parseFloat(countGasoline)
  countDiesel = parseFloat(countDiesel)

  let product_price = 70144.06 + 16082.85,
    one_time_income_gasoline = 143.04 + price,
    one_time_income_diesel = 225.51 + price,
    monthly_income = one_time_income_diesel * countDiesel + one_time_income_gasoline * countGasoline,
    yearly_income = monthly_income * 12,
    profitability = monthly_income / ((490.42 + price) * countGasoline + (773.16 + price) * countDiesel),
    payback_period = product_price / monthly_income,
    payback_period_with_adapters = payback_period,
    payback_period_without_adapters = (product_price - 16082.85) / monthly_income;

  let price_formatter = Intl.NumberFormat('uk-UA', { style: 'currency', currency: 'UAH' }),
    profitability_formatter = Intl.NumberFormat('uk-UA', { style: 'percent', maximumFractionDigits: 2}),
    payback_period_formatter = Intl.NumberFormat('uk-UA', { style: 'decimal', maximumFractionDigits: 1})

  let body = {
    customer_email: "",
    customer_name: "",
    product_name: "Jet Clean Tronic II",
    product_image: "werkstattwagen.png",
    product_price: price_formatter.format(product_price),
    monthly_income: price_formatter.format(monthly_income),
    yearly_income: price_formatter.format(yearly_income),
    profitability: profitability_formatter.format(profitability),
    payback_period: false,
    payback_period_with_adapters: `${payback_period_formatter.format(payback_period_with_adapters)} місяців`,
    payback_period_without_adapters: `${payback_period_formatter.format(payback_period_without_adapters)} місяців`
  };

  return (
    <Backdrop>
      <Wrapper>
        <Header inverted />
        <Main>
          <Title>Jet Clean Tronic II – {body.product_price}</Title>
          <Article>
            <Result>
              <Label>Дохід в місяць:</Label>
              <Value>{body.monthly_income}</Value>

              <Label>Дохід в рік:</Label>
              <Value>{body.yearly_income}</Value>

              <Label>Рентабельність:</Label>
              <Value>{body.profitability}</Value>

              <Label>Термін окупності без адаптерів:</Label>
              <Value>{body.payback_period_without_adapters}</Value>

              <Label>Термін окупності з ходовими адаптерами:</Label>
              <Value>{body.payback_period_with_adapters}</Value>
            </Result>
            <Product src={Werkstattwagen} alt='Jet Clean Tronic II' />
          </Article>
          <EmailButton body={body}>Надіслати розрахунок на e-mail</EmailButton>
          <Back to={`/services/gasoline-system-clean/${price}/${countGasoline}/${countDiesel}`}/>
        </Main>
      </Wrapper>
    </Backdrop>
  );
}
