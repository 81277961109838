import styled, { css } from "styled-components";

export const TextCenter = styled.div`
  text-align: center;
`

export const ButtonWrapper = styled.div`
  margin-top: 60px;
  margin-bottom: 5px;
`

export const Button = styled.button`
  width: 240px;
  height: 55px;

  background: #005BAA;
  border-radius: 18px;

  font-family: Proxima Nova Lt;
  font-weight: bold;
  font-size: 18px;
  line-height: 34px;
  text-transform: uppercase;

  color: #FFFFFF;
  border: none;

  :hover {
    cursor: pointer;
  }

  ${props => props.disabled && css`
    color: rgba(16, 16, 16, 0.3);
    background-color: rgba(239, 239, 239, 0.3);
    border-color: rgba(118, 118, 118, 0.3);
    cursor: not-allowed;
  `}
`

export const Title = styled.p`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 48px;
  line-height: 55px;
  color: #2A4763;
  margin: 6px 0 43px;
`

export const Label = styled.label`
  display: block;
  font-size: 14px;
  line-height: 27px;
  color: #2A4763;
  margin-bottom: 44px;
  
  > input {
    border: none;
    font-size: 14px;
    line-height: 27px;
    margin-top: 10px;
    width: 356px;
    border-bottom: 1px solid rgba(0, 91, 170, 0.2);
    padding-bottom: 2px;

    ::placeholder {
      color: rgba(42, 71, 99, 0.3);
      font-size: 14px;
      font-weight: 300;
    }
  }

  ${props => props.errors && css`
    > input {
      border-bottom: 1px solid red;
    }
  `}
`