import { useState } from 'react';
import { useParams } from "react-router-dom";
import { 
  Backdrop, Wrapper, Title, 
  Main, TitleImage, Article,
  ColHeading, RowHeading, Cell,
  Input, Product
} from '../../service-styles.js';
import { Header, LinkButton } from '../../../../components';

import { 
  geartronic,
  ATF1800,
  ProLine5188,
  ProLine5101
} from './images';

export default function ATF() {
  let params = useParams();
  const defaultPrice = parseFloat(params.price) || 0;
  const defaultCount = parseFloat(params.count) || 0;
  const defaultVolume = parseFloat(params.volume) || 0;
  const [price, setPrice] = useState(defaultPrice);
  const [count, setCount] = useState(defaultCount);
  const [volume, setVolume] = useState(defaultVolume);

  return (
    <Backdrop>
      <Wrapper>
        <Header inverted/>
        <Main>
          <Title>Gear Tronic II <TitleImage src={geartronic} alt='Gear Tronic II'/></Title>
          <Article>
            <ColHeading></ColHeading>
            <ColHeading>Ціна <br/> закупки</ColHeading>
            <ColHeading>Ціна <br/> продажу</ColHeading>
            <ColHeading>Націнка</ColHeading>

            <RowHeading>
              <Product src={ATF1800} alt='Top Tec ATF 1800'/>
              Top Tec <br/> ATF 1800
            </RowHeading>
            <Cell>295,36</Cell>
            <Cell>416,98</Cell>
            <Cell>41,2%</Cell>

            <RowHeading>Вартість <br/> послуги</RowHeading>
            <Cell withInput>
              <Input type='number' min='0' placeholder={defaultPrice} onChange={event => {
                setPrice(event.target.value);
              }}/>
            </Cell>
            <Cell textLeft>грн</Cell>
            <Cell></Cell>

            <RowHeading>Кількість <br/> послуг</RowHeading>
            <Cell withInput>
              <Input type='number' min='0' placeholder={defaultCount} onChange={event => {
                setCount(event.target.value);
              }}/>
            </Cell>
            <Cell textLeft>/місяць</Cell>
            <Cell></Cell>

            <RowHeading>Кількість л. <br/> за 1 заміну</RowHeading>
            <Cell withInput>
              <Input type='number' min='0' placeholder={defaultVolume} onChange={event => {
                setVolume(event.target.value);
              }}/>
            </Cell>
            <Cell textLeft>/л. за 1 заміну</Cell>
            <Cell></Cell>

            <RowHeading>
              <Product src={ProLine5188} alt='5188 Pro-Line Getriebegehäuseinnenreiniger 500ml'/>
              Getriebegehause <br/> Innenreiniger
            </RowHeading>
            <Cell>183,80</Cell>
            <Cell>259,50</Cell>
            <Cell></Cell>

            <RowHeading>
              <Product src={ProLine5101} alt='5101 Automatik Getriebe-Reiniger 1l'/>
              Automatik <br/> Getriebe-Reiniger
            </RowHeading>
            <Cell>252,00</Cell>
            <Cell>355,78</Cell>
            <Cell></Cell>
          </Article>
          <LinkButton to={`/services/atf-change/${price || defaultPrice}/${count || defaultCount}/${volume || defaultVolume}/result`}>Розрахувати</LinkButton>
        </Main>
      </Wrapper>
    </Backdrop>
  );
}