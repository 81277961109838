import styled from "styled-components";
import { Link } from "react-router-dom";

export const Backdrop = styled.div`
  background: rgba(0, 91, 170, 0.04);
  min-height: 100vh;
`;

export const Wrapper = styled.section`
  padding: 41px;
`;

export const Title = styled.h1`
  margin-top 75px;
  font-weight: bold;
  font-size: 24px;
  line-height: 46px;

  color: #2A4763;
`;

export const Main = styled.main`
  margin-left: 19px;
`;

export const ServiceWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(260px, 312px));
  grid-gap: 20px;
  grid-auto-rows: 200px;
`;

export const ServiceLink = styled(Link)`
  text-decoration: none;
`;