import styled from "styled-components";
import { Logo } from '../../components';
import { Link } from "react-router-dom";
import { CloseIcon } from './images';

export const Wrapper = styled.header`
  margin: 41px 3px 0px;
  position: relative;
`;

export const Close = styled(Link)`
  position: absolute;
  right: 0;
  top: 0;
`;

export default function Header(args) {
  return (
    <Wrapper>
      <Logo inverted={args.inverted}/>
      {args.inverted && 
        <Close to='/choose'>
          <img src={CloseIcon} alt='Close'/>
        </Close>
      }
    </Wrapper>
  );
}