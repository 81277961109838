import { useState } from 'react';
import { useParams } from "react-router-dom";
import { 
  Backdrop, Wrapper, Title, 
  Main, TitleImage, Article,
  ColHeading, RowHeading, Cell,
  Input, Product, Footnote
} from '../../service-styles.js';
import { Header, LinkButton } from '../../../../components';
import { 
  Klimaanlagen,
  Klimaanlagenreiniger
} from './images';

export default function ACSystem() {
  let params = useParams();
  const defaultPrice = parseFloat(params.price) || 0;
  const defaultCount = parseFloat(params.count) || 0;
  const [price, setPrice] = useState(defaultPrice);
  const [count, setCount] = useState(defaultCount);

  return (
    <Backdrop>
      <Wrapper>
        <Header inverted/>
        <Main>
          <Title>Прилад для очистки кондиціонера <TitleImage src={Klimaanlagen} alt='Klimaanlagen Reinigungspistole'/></Title>
          <Article>
            <ColHeading></ColHeading>
            <ColHeading>Ціна <br/> закупки</ColHeading>
            <ColHeading>Ціна <br/> продажу</ColHeading>
            <ColHeading>Націнка</ColHeading>

            <RowHeading>
              <Product src={Klimaanlagenreiniger} alt='4091 Klimaanlagenreiniger 1l 1'/>
              Benzin System <br/> Intensiv Reiniger
            </RowHeading>
            <Cell>173,20*</Cell>
            <Cell>244,56</Cell>
            <Cell>41,2%</Cell>

            <RowHeading>Вартість <br/> послуги</RowHeading>
            <Cell withInput>
              <Input type='number' min='0' placeholder={defaultPrice} onChange={event => {
                setPrice(event.target.value);
              }}/>
            </Cell>
            <Cell textLeft>грн</Cell>
            <Cell></Cell>

            <RowHeading>Кількість <br/> послуг</RowHeading>
            <Cell withInput>
              <Input type='number' min='0' placeholder={defaultCount} onChange={event => {
                setCount(event.target.value);
              }}/>
            </Cell>
            <Cell textLeft>/місяць</Cell>
            <Cell></Cell>

            <Footnote>* Вартість 0,5 л.</Footnote>

          </Article>
          <LinkButton to={`/services/ac-system-clean/${price || defaultPrice}/${count || defaultCount}/result`}>Розрахувати</LinkButton>
        </Main>
      </Wrapper>
    </Backdrop>
  );
}