import { useParams } from "react-router-dom";
import {
  Backdrop,
  Wrapper,
  Title,
  Main,
  Article,
  Result,
  Label,
  Value,
  Product,
} from "../../service-result-styles.js";
import { Header, EmailButton, Back } from "../../../../components";

import { geartronic } from "./images";

export default function ATF() {
  let { price, count, volume } = useParams();
  price = parseFloat(price)
  count = parseFloat(count)
  volume = parseFloat(volume)

  let product_price = 179546.45 + 14528.03,
    one_time_income = 121.62 * volume + 74.02 + 75.68 + price,
    monthly_income = one_time_income * count,
    yearly_income = monthly_income * 12,
    profitability = one_time_income / (416.98 * volume + 253.78 + 259.49 + price),
    payback_period = product_price / monthly_income,
    payback_period_with_adapters = payback_period,
    payback_period_without_adapters = (product_price - 14528.03) / monthly_income;

  let price_formatter = Intl.NumberFormat('uk-UA', { style: 'currency', currency: 'UAH' }),
    profitability_formatter = Intl.NumberFormat('uk-UA', { style: 'percent', maximumFractionDigits: 2}),
    payback_period_formatter = Intl.NumberFormat('uk-UA', { style: 'decimal', maximumFractionDigits: 1})

  let body = {
    customer_email: "",
    customer_name: "",
    product_name: "Gear Tronic II",
    product_image: "geartronic3.png",
    product_price: price_formatter.format(product_price),
    monthly_income: price_formatter.format(monthly_income),
    yearly_income: price_formatter.format(yearly_income),
    profitability: profitability_formatter.format(profitability),
    payback_period: false,
    payback_period_with_adapters: `${payback_period_formatter.format(payback_period_with_adapters)} місяців`,
    payback_period_without_adapters: `${payback_period_formatter.format(payback_period_without_adapters)} місяців`
  };

  return (
    <Backdrop>
      <Wrapper>
        <Header inverted />
        <Main>
          <Title>Gear Tronic II – {body.product_price}</Title>
          <Article>
            <Result>
              <Label>Дохід в місяць:</Label>
              <Value>{body.monthly_income}</Value>

              <Label>Дохід в рік:</Label>
              <Value>{body.yearly_income}</Value>

              <Label>Рентабельність:</Label>
              <Value>{body.profitability}</Value>

              <Label>Термін окупності без адаптерів:</Label>
              <Value>{body.payback_period_without_adapters}</Value>

              <Label>Термін окупності з ходовими адаптерами:</Label>
              <Value>{body.payback_period_with_adapters}</Value>
            </Result>
            <Product src={geartronic} alt='Gear Tronic II'/>
          </Article>
          <EmailButton body={body}>Надіслати розрахунок на e-mail</EmailButton>
          <Back to={`/services/atf-change/${price}/${count}/${volume}`} />
        </Main>
      </Wrapper>
    </Backdrop>
  );
}
