import styled, {css} from "styled-components";
import { CloseIcon } from './images';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(10px);

  display: none;
  justify-content: center;
  align-content: center;

  ${props => props.isOpen && css`
    display: grid;
  `}
`;

const ModalStyled = styled.div`
  position: relative;
  background: #FFFFFF;
  border-radius: 20px;
  padding: 45px;
  width: 459px;
`;

const Close = styled.span`
  position: absolute;
  right: 40px;
  top: 30px;

  :hover {
    cursor: pointer;
  }
`;

export default function Modal({ isOpen, toggle, children }) {
  return (
    <ModalBackground isOpen={isOpen}>
      <ModalStyled>
        {children}
        <Close onClick={() => toggle(false)}>
          <img src={CloseIcon} alt='Close'/>
        </Close>
      </ModalStyled>
    </ModalBackground>
  );
}