import styled from "styled-components";
import { Button } from '../../components';
import { Link } from "react-router-dom";

export const LinkStyled = styled(Link)`
  text-decoration: none;
`;

export const TextCenter = styled.div`
  text-align: center;
`

export default function LinkButton(args) {
  return (
    <TextCenter>
      <LinkStyled to={args.to}>
        <Button radius={args.radius}>{args.children}</Button>
      </LinkStyled>
    </TextCenter>
  );
}