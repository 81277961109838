import { useParams } from "react-router-dom";
import {
  Backdrop,
  Wrapper,
  Title,
  Main,
  Article,
  Result,
  Label,
  Value,
  Product,
} from "../../service-result-styles.js";
import { Header, EmailButton, Back } from "../../../../components";

import { dpf } from "./images";

export default function DPF() {
  let { price, count } = useParams();
  price = parseFloat(price)
  count = parseFloat(count)

  let product_price = 9089.05 + 4085.30,
    one_time_income = (1513.68 + 848.64) - (1072.19 + 601.12) + price,
    monthly_income = one_time_income * count,
    yearly_income = monthly_income * 12,
    profitability = one_time_income / (1513.68 + 848.64 +price),
    payback_period = product_price / monthly_income;

  let price_formatter = Intl.NumberFormat('uk-UA', { style: 'currency', currency: 'UAH' }),
    profitability_formatter = Intl.NumberFormat('uk-UA', { style: 'percent', maximumFractionDigits: 2}),
    payback_period_formatter = Intl.NumberFormat('uk-UA', { style: 'decimal', maximumFractionDigits: 1})

  let body = {
    customer_email: "",
    customer_name: "",
    product_name: "Прилад для очистки DPF",
    product_image: "dpf.png",
    product_price: price_formatter.format(product_price),
    monthly_income: price_formatter.format(monthly_income),
    yearly_income: price_formatter.format(yearly_income),
    profitability: profitability_formatter.format(profitability),
    payback_period: `${payback_period_formatter.format(payback_period)} місяців`,
    payback_period_with_adapters: false,
    payback_period_without_adapters: false
  };

  return (
    <Backdrop>
      <Wrapper>
        <Header inverted />
        <Main>
          <Title>Прилад для очистки DPF – {body.product_price}</Title>
          <Article>
            <Result>
              <Label>Дохід в місяць:</Label>
              <Value>{body.monthly_income}</Value>

              <Label>Дохід в рік:</Label>
              <Value>{body.yearly_income}</Value>

              <Label>Рентабельність:</Label>
              <Value>{body.profitability}</Value>

              <Label>Термін окупності:</Label>
              <Value>{body.payback_period}</Value>
            </Result>
            <Product src={dpf} alt='Druckbecherpistole and Lanze mit 5 Sprühsonden'/>
          </Article>
          <EmailButton body={body}>Надіслати розрахунок на e-mail</EmailButton>
          <Back to={`/services/dpf-clean/${price}/${count}`}/>
        </Main>
      </Wrapper>
    </Backdrop>
  );
}
