import { useState } from "react";
import styled from "styled-components";
import { Button, Modal, EmailForm } from '../../components';

const TextCenter = styled.div`
  text-align: center;
`

const ButtonWrapper = styled.div`
  display: inline-block;
  
  :hover {
    cursor: pointer;
  }
`

export default function EmailButton(args) {
  const [isModalOpen, toggleModal] = useState(false);

  return (
    <div>
      <TextCenter>
        <ButtonWrapper onClick={() => toggleModal(true)}>
          <Button>{args.children}</Button>
        </ButtonWrapper>
      </TextCenter>
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <EmailForm body={args.body}/>
      </Modal>
    </div>
  );
}