import { useState } from 'react';
import { useParams } from "react-router-dom";
import { 
  Backdrop, Wrapper, Title, 
  Main, TitleImage, Article,
  ColHeading, RowHeading, Cell,
  Input, Product, Footnote
} from '../../service-styles.js';
import { Header, LinkButton } from '../../../../components';
import { 
  Werkstattwagen,
  ProLine5147,
  ProLine5149
} from './images';

export default function DPF() {
  let params = useParams();
  const defaultPrice = parseFloat(params.price) || 0;
  const defaultCountGasoline = parseFloat(params.countGasoline) || 0;
  const defaultCountDiesel = parseFloat(params.countDiesel) || 0;
  const [price, setPrice] = useState(defaultPrice);
  const [countGasoline, setCountGasoline] = useState(defaultCountGasoline);
  const [countDiesel, setCountDiesel] = useState(defaultCountDiesel);

  return (
    <Backdrop>
      <Wrapper>
        <Header inverted/>
        <Main>
          <Title>Jet Clean Tronic II <TitleImage src={Werkstattwagen} alt='Werkstattwagen Produktbilder'/></Title>
          <Article>
            <ColHeading></ColHeading>
            <ColHeading>Ціна <br/> закупки</ColHeading>
            <ColHeading>Ціна <br/> продажу</ColHeading>
            <ColHeading>Націнка</ColHeading>

            <RowHeading>
              Адаптери <br/> (азія)
            </RowHeading>
            <Cell>9460,42</Cell>
            <Cell></Cell>
            <Cell></Cell>

            <RowHeading>
              Адаптери <br/> (Європа)
            </RowHeading>
            <Cell>9460,42</Cell>
            <Cell></Cell>
            <Cell></Cell>

            <RowHeading>
              <Product src={ProLine5147} alt='Pro-Line JetClean Benzin-System-Reiniger 1l'/>
              Benzin System <br/> Intensiv Reiniger
            </RowHeading>
            <Cell>347,38</Cell>
            <Cell>490,42</Cell>
            <Cell>41,2%</Cell>

            <RowHeading>
              <Product src={ProLine5149} alt='Pro-Line JetClean Diesel-System-Reiniger 1l'/>
              Diesel System <br/> Intensiv Reiniger
            </RowHeading>
            <Cell>547,66</Cell>
            <Cell>773,16</Cell>
            <Cell></Cell>

            <RowHeading>Вартість <br/> послуги</RowHeading>
            <Cell withInput withAsterisk>
              <Input type='number' min='0' placeholder={defaultPrice} onChange={event => {
                setPrice(event.target.value);
              }}/>
            </Cell>
            <Cell textLeft>грн</Cell>
            <Cell></Cell>

            <RowHeading>Кількість <br/> послуг бензин</RowHeading>
            <Cell withInput>
              <Input type='number' min='0' placeholder={defaultCountGasoline} onChange={event => {
                setCountGasoline(event.target.value);
              }}/>
            </Cell>
            <Cell textLeft>/місяць</Cell>
            <Cell></Cell>

            <RowHeading>Кількість <br/> послуг дизель</RowHeading>
            <Cell withInput>
              <Input type='number' min='0' placeholder={defaultCountDiesel} onChange={event => {
                setCountDiesel(event.target.value);
              }}/>
            </Cell>
            <Cell textLeft>/місяць</Cell>
            <Cell></Cell>

            <Footnote>* При умові, що середній об’єм двигуна 2 літри.</Footnote>

          </Article>
          <LinkButton to={`/services/gasoline-system-clean/${price || defaultPrice}/${countGasoline || defaultCountGasoline}/${countDiesel || defaultCountDiesel}/result`}>Розрахувати</LinkButton>
        </Main>
      </Wrapper>
    </Backdrop>
  );
}